import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { react2angular } from 'react2angular';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import * as Sentry from '@sentry/react';

import GlobalStyle from './GlobalStyle';
import WhiteLabel from './filters/WhiteLabel';
import capitalizeFilter from './filters/capitalize-filter';

import { AnalyticsProvider } from './analytics';
import { UserProvider } from './authentication';
import { AngularProvider } from './contexts/AngularContext';
import { OrgProvider } from './contexts/OrgContext';
import { CurriculumProvider } from './contexts/CurriculumContext';

import AccountProfile from './components/AccountProfile';

import BibleStudies from './curriculum/BibleStudies';
import SubscribeBibleStudy from './curriculum/SubscribeBibleStudy';

import ManageBibleStudyPeople from './curriculum/components/ManageBibleStudyPeople';
import ManageBibleStudyCollection from './curriculum/ManageBibleStudyCollection';
import ManageBibleStudySchedule from './curriculum/components/ManageBibleStudySchedule';
import ManageBibleStudySession from './curriculum/ManageBibleStudySession';
import ManageBibleStudySessionTimeline from './curriculum/ManageBibleStudySessionTimeline';
import ManageBibleStudyAdvancePlanning from './curriculum/ManageBibleStudyAdvancePlanning';
import ManageBibleStudyResetSchedule from './curriculum/ManageBibleStudyResetSchedule';

import BibleStudySession from './curriculum/BibleStudySession';
import BibleStudySessionMaterial from './curriculum/BibleStudySessionMaterial';
import BibleStudySessionTimeline from './curriculum/BibleStudySessionTimeline';
import BibleStudySessionTimelinePreview from './curriculum/BibleStudySessionTimelinePreview';

import CurriculumAddPeople from './curriculum/CurriculumAddPeople';

import HelpAndFeedback from './components/HelpAndFeedback';

import AssessmentSettings from './training/AssessmentSettings';
import AssessmentBuilder from './training/AssessmentBuilder';
import AssessmentResults from './training/AssessmentResults';
import AssessmentResultsViewer from './training/AssessmentResultsViewer';
import AssessmentQuestionView from './training/AssessmentQuestionView';
import AssessmentResultsFilter from './training/AssessmentResultsFilter';

import ManageCurriculum from './curriculum/builder/ManageCurriculum';
import ManageCurriculumCollection from './curriculum/builder/ManageCurriculumCollection';
import ManageCurriculumVolume from './curriculum/volume/ManageCurriculumVolume';
import ManageCurriculumVolumeHeader from './curriculum/volume/ManageCurriculumVolumeHeader';
import AddVolumeHeaderMaterial from './curriculum/volume/AddVolumeHeaderMaterial';
import ManageCurriculumUnit from './curriculum/builder/ManageCurriculumUnit';
import ManageCurriculumSession from './curriculum/builder/ManageCurriculumSession';
import ManageCurriculumMaterial from './curriculum/builder/ManageCurriculumMaterial';

import PairLanding from './pairing/PairLanding';
import RecentPurchases from './pairing/RecentPurchases';
import SelectOrg from './pairing/SelectOrg';
import BrowserSupport from './support/BrowserSupport';

import UnauthorizedAccount from './status/UnauthorizedAccount';

// TODO: these should probably be grouped in an org folder
import CreateOrg from './components/CreateOrg';
import SwitchOrg from './components/SwitchOrg';
import OrganizationSettings from './components/OrganizationSettings';
import OrganizationOverview from './org/OrganizationOverview';
import { ManageLicenses, AddPeopleToLicense, ManageSubscriptions } from './manageSubscriptions';

import ManageRole from './org/ManageRole';
import Roles from './org/Roles';
import RoleAddPeople from './org/RoleAddPeople';
import ManageAssignments from './manageAssignments/ManageAssignments';
import ManageAssignmentsViewAssignment from './manageAssignments/ManageAssignmentsViewAssignment';
import TrainingProgress from './trainingProgress/TrainingProgress';
import Person from './people/Person';
import EmailPerson from './people/EmailPerson';
import EmailGroup from './people/EmailGroup';
import ManageTrainingCatalog from './catalog/ManageTrainingCatalog';
import ManageCarouselItem from './catalog/ManageCarouselItem';
import ManageTrainingCompilations from './catalog/ManageTrainingCompilations';
import ManageTrainingCompilation from './catalog/ManageTrainingCompilation';
import ManageTrainingButton from './catalog/ManageTrainingButton';
import AddTrainingCompilation from './catalog/AddTrainingCompilation';
import { AddTrainingToCarousel } from './catalog/AddTrainingToCarousel';
import { AddTrainingToButton } from './catalog/AddTrainingToButton';
import { AddTrainingToCompilation } from './catalog/AddTrainingToCompilation';
import ManageCurriculums from './curriculum/builder/ManageCurriculums';
import SessionCalendar from './curriculum/timeline/SessionCalendar';
import ManageSessionTimeline from './curriculum/timeline/ManageSessionTimeline';
import ManageSession from './curriculum/timeline/ManageSession';
import AddSectionMedia from './curriculum/timeline/AddSectionMedia';
import { ManagePeople, AddPeople } from './people';
import Group from './groups/Group';
import GroupAddPeople from './groups/GroupAddPeople';
import Onboarding from './curriculum/onboarding/Onboarding';
import AssignTraining from './training/AssignTraining';
import RemovePeopleFromOrg from './people/RemovePeopleFromOrg';
import AdvancedInvites from './invites/AdvancedInvites';
import AdvancedInvitesEasyLink from './invites/AdvancedInvitesEasyLink';
import {
  ViewInvite,
  InviteSettings,
  AddTrainingToInvite,
  AddGroupsToInvite,
  AddRolesToInvite,
  AcceptInvite,
  ManageEmailInvite,
} from './invites';

import {
  ManageTraining,
  PathwayBuilder,
  ManagePathwayEmail,
  SelectCourse,
  CourseBuilder,
  ManageVideoContent,
} from './manageTraining';
import PathwaySettings from './training/PathwaySettings';
import CourseSettings from './training/CourseSettings';

import AdminController from './admin/AdminController';
import PurchaseRedirectHandler from './components/PurchaseRedirectHandler';
import PendingInvites from './invites/PendingInvites';
import ServiceUnavailable from './status/ServiceUnavailable';
import SearchTraining from './training/SearchTraining';

/**
 * path: the route to come after the `/#`; requires angular postfix route in the routes.js file
 * title: the title for the page this will be used later to change the title in the head
 * component: the component to be rendered for the path; it will be passed the route props
 * withNavbar: takes the angular navbar into account when positioning the content; should be `false` for fullscreen pages
 */
const routes = [
  { path: '/people', title: 'People', component: ManagePeople },
  { path: '/people/add', title: 'Add People', component: AddPeople },
  { path: '/groups', title: 'People', component: ManagePeople },
  { path: '/groups/:groupId/:orgId', title: 'People', component: Group },
  { path: '/groups/:groupId/:orgId/add-people', title: 'People', component: GroupAddPeople },
  { path: '/roles', title: 'Roles', component: Roles },
  { path: '/roles/:roleId', title: 'Roles', component: ManageRole },
  { path: '/roles/:roleId/add-people', title: 'Roles', component: RoleAddPeople },

  { path: '/org', title: 'Organization', component: OrganizationOverview },

  { path: '/assign-training/:trainingId', title: 'Catalog', component: AssignTraining },

  { path: '/people/remove', title: 'Remove People', component: RemovePeopleFromOrg },

  { path: '/account-profile', title: 'Account Profile', component: AccountProfile },
  { path: '/bible-studies', title: 'Curriculum', component: BibleStudies },
  { path: '/subscribe-bible-study/:crmBrandCode/:curriculumId', title: 'Curriculum', component: SubscribeBibleStudy },
  {
    path: '/manage-bible-study/people/:crmBrandCode/:crmAgeCategory/:curriculumId',
    title: 'People',
    component: ManageBibleStudyPeople,
  },
  {
    path: '/manage-bible-study/people/collection/:crmBrandCode/:crmAgeCategory/:curriculumId/:collectionId',
    title: 'Collection',
    component: ManageBibleStudyCollection,
  },
  {
    path: '/manage-bible-study/people/collection/add-people/:crmBrandCode/:crmAgeCategory/:curriculumId/:collectionId',
    title: 'Add People',
    component: CurriculumAddPeople,
  },
  {
    path: '/manage-bible-study/schedule/:crmBrandCode/:crmAgeCategory/:curriculumId',
    title: 'Schedule',
    component: ManageBibleStudySchedule,
  },
  {
    path: '/manage-bible-study/schedule/:crmBrandCode/:crmAgeCategory/:curriculumId/:sessionId',
    title: 'Session',
    component: ManageBibleStudySession,
  },
  {
    path: '/manage-bible-study/schedule/:crmBrandCode/:crmAgeCategory/:curriculumId/:sessionId/timeline/:originalTimelineId/:timelineId',
    title: 'Session Timeline',
    component: ManageBibleStudySessionTimeline,
  },
  {
    path: '/manage-bible-study/advance-planning/:crmBrandCode/:crmAgeCategory/:curriculumId',
    title: 'Advance Planning',
    component: ManageBibleStudyAdvancePlanning,
  },
  {
    path: '/manage-bible-study/reset-schedule/:crmBrandCode/:crmAgeCategory/:curriculumId',
    title: 'Reset Schedule',
    component: ManageBibleStudyResetSchedule,
  },

  { path: '/help', title: 'Help And Feedback', component: HelpAndFeedback },

  { path: '/pairing/purchases', title: 'Recent Purchases', component: RecentPurchases, withNavbar: false },
  {
    path: '/pairing/select-org',
    title: `Select A ${capitalizeFilter(WhiteLabel.labels.org)}`,
    component: SelectOrg,
    withNavbar: false,
  },
  { path: '/pairing/:checkout?', title: 'Purchase Pairing', component: PairLanding, withNavbar: false },

  {
    path: '/org/create',
    title: `Create A ${capitalizeFilter(WhiteLabel.labels.org)}`,
    component: CreateOrg,
    withNavbar: false,
  },
  {
    path: '/org/switch',
    title: `Select A ${capitalizeFilter(WhiteLabel.labels.org)}`,
    component: SwitchOrg,
    withNavbar: false,
  },
  { path: '/org/settings', title: 'Organization Settings', component: OrganizationSettings },
  { path: '/org/purchases-and-subscriptions', title: 'Purchases and Subscriptions', component: ManageSubscriptions },
  { path: '/org/purchases-and-subscriptions/:licenseId', title: 'Licenses', component: ManageLicenses },
  {
    path: '/org/purchases-and-subscriptions/:licenseId/add',
    title: 'Licenses',
    component: AddPeopleToLicense,
  },

  { path: '/person/:id', title: 'Organization', component: Person },

  { path: '/manage-catalog', title: 'Manage Training Catalog', component: ManageTrainingCatalog },
  {
    path: '/manage-catalog/feature-carousel/:carouselIndex?',
    title: 'Manage Training Catalog',
    component: ManageTrainingCatalog,
  },
  {
    path: '/manage-catalog/carousel-item/:atIndex/:id?/add-training',
    title: 'Manage Training Catalog',
    component: AddTrainingToCarousel,
  },
  {
    path: '/manage-catalog/training-button/:buttonType/:id?/add-training',
    title: 'Manage Training Catalog',
    component: AddTrainingToButton,
  },
  {
    path: '/manage-catalog/carousel-item/:atIndex/:id?',
    title: 'Manage Training Catalog',
    component: ManageCarouselItem,
  },
  {
    path: '/manage-catalog/training-button/:buttonType/:id?',
    title: 'Manage Training Catalog',
    component: ManageTrainingButton,
  },
  { path: '/manage-catalog/compilations', title: 'Manage Training Catalog', component: ManageTrainingCompilations },
  {
    path: '/manage-catalog/compilation/:id?/add-training',
    title: 'Manage Training Catalog',
    component: AddTrainingToCompilation,
  },
  { path: '/manage-catalog/compilation/:id?', title: 'Manage Training Catalog', component: ManageTrainingCompilation },
  {
    path: '/manage-catalog/add-compilation/:index',
    title: 'Manage Training Catalog',
    component: AddTrainingCompilation,
  },
  {
    path: '/manage-catalog/add-compilation-to-carousel-item',
    title: 'Manage Training Catalog',
    component: AddTrainingCompilation,
  },
  {
    path: '/manage-catalog/add-compilation-to-training-button',
    title: 'Manage Training Catalog',
    component: AddTrainingCompilation,
  },

  {
    path: '/manage-assignments',
    title: 'Organization',
    component: ManageAssignments,
  },

  {
    path: '/manage-assignments/:assignmentId',
    title: 'Organization',
    component: ManageAssignmentsViewAssignment,
  },
  {
    path: '/manage-assignments/:assignmentId/view/:assessmentId/:responseId',
    title: 'Assessment Results',
    component: AssessmentResultsViewer,
  },

  { path: '/manage-curriculum', title: 'Manage Curriculum', component: ManageCurriculums },
  // Manage Curriculum - Non-Timeline Enabled (legacy)
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId',
    title: 'Manage Curriculum',
    component: ManageCurriculum,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/unit/:unitId',
    title: 'Manage Curriculum',
    component: ManageCurriculumUnit,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/unit/:unitId/session',
    title: 'Manage Curriculum',
    component: ManageCurriculumSession,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/unit/:unitId/session/:sessionId/settings',
    title: 'Manage Curriculum',
    component: ManageCurriculumSession,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/unit/:unitId/session/:sessionId/material',
    title: 'Manage Curriculum',
    component: ManageCurriculumMaterial,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/unit/:unitId/session/:sessionId/material/:materialId/settings',
    title: 'Manage Curriculum',
    component: ManageCurriculumMaterial,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/volume',
    title: 'Manage Curriculum',
    component: ManageCurriculumVolume,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/volume/:volumeId',
    title: 'Manage Curriculum',
    component: ManageCurriculumVolume,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/volume/:volumeId/header/:headerId',
    title: 'Manage Curriculum',
    component: ManageCurriculumVolumeHeader,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/volume/:volumeId/header/:headerId/add-material',
    title: 'Manage Curriculum',
    component: AddVolumeHeaderMaterial,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/collection',
    title: 'Manage Curriculum',
    component: ManageCurriculumCollection,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/collection/:collectionId',
    title: 'Manage Curriculum',
    component: ManageCurriculumCollection,
  },
  // Mange Curriculum - Timeline Enabled
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/session-calendar',
    title: 'Manage Curriculum',
    component: SessionCalendar,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/session/:sessionId/timeline/:timelineId',
    title: 'Manage Curriculum',
    component: ManageSessionTimeline,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/session/:sessionId/timeline/:timelineId/edit',
    title: 'Manage Curriculum',
    component: ManageSession,
  },
  {
    path: '/manage-curriculum/:crmBrandCode/:crmAgeCategory/:curriculumId/session/:sessionId/timeline/:timelineId/add-media/:sectionId',
    title: 'Manage Curriculum',
    component: AddSectionMedia,
  },
  // End Manage Curriculum Routes
  {
    path: '/curriculum/:crmBrandCode/:crmAgeCategory/session/:sessionId/issue/:issueId',
    title: 'Curriculum',
    component: BibleStudySession,
    withNavbar: false,
  },
  {
    path: '/curriculum/:crmBrandCode/:crmAgeCategory/session/:sessionId/material/:materialId',
    title: 'Curriculum',
    component: BibleStudySessionMaterial,
    withNavbar: false,
  },
  {
    path: '/curriculum/:crmBrandCode/:crmAgeCategory/session/:sessionId/issue/:issueId/timeline/:timelineId/preview',
    title: 'Curriculum',
    component: BibleStudySessionTimelinePreview,
    withNavbar: false,
  },
  {
    path: '/curriculum/:crmBrandCode/:crmAgeCategory/session/:sessionId/issue/:issueId/timeline/:timelineId/:timelineCustomizationId/preview',
    title: 'Curriculum',
    component: BibleStudySessionTimelinePreview,
    withNavbar: false,
  },
  {
    path: '/curriculum/:crmBrandCode/:crmAgeCategory/session/:sessionId/issue/:issueId/timeline/:timelineId/:timelineCustomizationId?',
    title: 'Curriculum',
    component: BibleStudySessionTimeline,
    withNavbar: false,
  },
  {
    path: '/search',
    title: 'Search',
    component: SearchTraining,
  },
  {
    path: '/email/person/:orgId/:personId',
    title: 'People',
    component: EmailPerson,
  },
  {
    path: '/email/group/:orgId/:groupId',
    title: 'Organization',
    component: EmailGroup,
  },
  {
    path: '/manage-training/:buildType/assessments/:assessmentId',
    title: 'Assessment Builder',
    component: AssessmentBuilder,
  },
  {
    path: '/manage-training/:buildType/assessments/:assessmentId/settings',
    title: 'Assessment Settings',
    component: AssessmentSettings,
  },
  {
    path: '/manage-training/:buildType/assessments/:assessmentId/questions/:questionId?',
    title: 'Assessment Question View',
    component: AssessmentQuestionView,
  },
  {
    path: '/curriculum/onboarding/:crmBrandCode/:step?',
    title: 'Curriculum',
    component: Onboarding,
    withNavbar: false,
  },
  {
    path: '/assessment/results',
    title: 'Assessment Results',
    component: AssessmentResults,
  },
  {
    path: '/assessment/results/:assessmentId',
    title: 'Assessment Results Viewer',
    component: AssessmentResultsViewer,
  },
  {
    path: '/assessment/results/:assessmentId/filter',
    title: 'Assessment Results Filter',
    component: AssessmentResultsFilter,
  },
  {
    path: '/training-progress',
    title: 'Trainging Progress',
    component: TrainingProgress,
  },
  {
    path: '/admin/*',
    title: 'Administration',
    component: AdminController,
  },
  {
    path: '/purchase',
    title: 'Purchase',
    component: PurchaseRedirectHandler,
  },
  {
    path: '/browser-support',
    title: 'Browser Support',
    component: BrowserSupport,
    withNavbar: false,
  },
  {
    path: '/invites',
    title: 'Invites',
    component: AdvancedInvites,
  },
  {
    path: '/invite/accept/:orgId/:forEmail',
    title: 'Accept Invite',
    component: AcceptInvite,
    withNavbar: false,
  },
  {
    path: '/invite/:orgId/code/view/:inviteId',
    title: 'Invites',
    component: AdvancedInvitesEasyLink,
  },
  {
    path: '/invite/:orgId/:inviteType/add-training/:inviteId?/:settingsId?',
    title: 'Invites',
    component: AddTrainingToInvite,
  },
  {
    path: '/invite/:orgId/:inviteType/add-groups',
    title: 'Invites',
    component: AddGroupsToInvite,
  },
  {
    path: '/invite/:orgId/:inviteType/add-roles',
    title: 'Invites',
    component: AddRolesToInvite,
  },
  {
    path: '/invite/:orgId/:inviteType/settings/:inviteId?/:settingsId?',
    title: 'Invite Settings',
    component: InviteSettings,
  },
  {
    path: '/invite/:orgId/email/preview/:inviteId?',
    title: 'Invites',
    component: ManageEmailInvite,
  },
  {
    path: '/invite/:orgId/:inviteType/:inviteId',
    title: 'Invites',
    component: ViewInvite,
  },
  {
    path: '/manage-training/:buildType/:trainingType',
    title: 'Manage Training',
    component: ManageTraining,
  },
  {
    path: '/manage-training/:buildType/pathways/:trainingId',
    title: 'Manage Training',
    component: PathwayBuilder,
  },
  {
    path: '/manage-training/:buildType/pathways/:trainingId/settings',
    title: 'Manage Training',
    component: PathwaySettings,
  },
  {
    path: '/manage-training/:buildType/:trainingType/:trainingId/select',
    title: 'Manage Training',
    component: SelectCourse,
  },
  {
    path: '/manage-training/:buildType/courses/:trainingId',
    title: 'Manage Training',
    component: CourseBuilder,
  },
  {
    path: '/manage-training/:buildType/courses/:trainingId/settings',
    title: 'Manage Training',
    component: CourseSettings,
  },
  {
    path: '/manage-training/:buildType/:trainingType/:trainingId/email/:emailId?',
    title: 'Manage Training',
    component: ManagePathwayEmail,
  },
  {
    path: '/manage-training/:buildType/content/:contentId',
    title: 'Manage Training',
    component: ManageVideoContent,
  },
  {
    path: '/pending-invites',
    title: 'Pending Invites',
    component: PendingInvites,
    withNavbar: false,
  },
  {
    path: '/401',
    title: 'Unauthorized',
    component: UnauthorizedAccount,
  },
  { path: '/503', title: 'Service Unavailable', component: ServiceUnavailable, withNavbar: false },
];

const ContentWrapper = styled.div`
  &.with-navbar {
    padding-top: 60px;
  }
`;

export default function ReactApp({ $rootScope, user, org }) {
  return (
    <AngularProvider scope={$rootScope}>
      <UserProvider user={user}>
        <OrgProvider org={org ? org : null}>
          <GlobalStyle />
          <Notifications />
          <Router>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  exact
                  path={route.path}
                  key={index}
                  render={props => {
                    $rootScope.pageTitle = `${WhiteLabel.name} - ${route.title}`; // TODO: remove when transitioned from AngularJS
                    return (
                      <CurriculumProvider>
                        <AnalyticsProvider pageName={route.component.name}>
                          <ContentWrapper className={route.withNavbar === false ? '' : 'with-navbar'}>
                            <route.component {...props} />
                          </ContentWrapper>
                        </AnalyticsProvider>
                      </CurriculumProvider>
                    );
                  }}
                />
              ))}
            </Switch>
          </Router>
        </OrgProvider>
      </UserProvider>
    </AngularProvider>
  );
}

ReactApp.propTypes = {
  user: PropTypes.object,
  org: PropTypes.object,
  $rootScope: PropTypes.object,
};

function deriveEnvironment() {
  const { hostname } = window.location;
  if (hostname.includes('local')) {
    return 'local';
  } else if (hostname.includes('dev') || hostname.includes('int')) {
    return 'integration';
  } else if (hostname.includes('stage') || hostname.includes('uat')) {
    return 'user-acceptance';
  } else {
    return 'production';
  }
}

Sentry.init({
  dsn: 'https://b75a765e71264d7387be8b50335e8d2d@o99218.ingest.sentry.io/4505319015448576',
  environment: deriveEnvironment(),
  ignoreErrors: [
    // OIDC Client Library Errors
    // TODO: Fixing these issues in the client library helps all apps that use the library. No user impact has been observed due to these issues at this time. - Jordan Fjellman 09/07/2023
    'No matching state found in storage',
    'Worker was terminated',
    'Load failed',
    // Authorization errors are not sent because they are used to determine if the user is logged in
    'Request failed with status code 403',
    // AngularJS error that was reoccurring for a small number of users
    '[$rootScope:inprog]',
    // Fullstory API error
    'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection',
  ],
});

// eslint-disable-next-line no-undef
angular.module('lwNamb').component('reactApp', react2angular(ReactApp, ['user', 'org'], ['$rootScope']));
