import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints, Grid, Type } from '../StyleGuide';
import { useUser } from '../authentication';
import Breadcrumbs from '../components/Breadcrumbs';
import { OutlineButton, PrimaryButton } from '../components/Buttons';
import EasyLinkModal from '../components/EasyLinkModal';
import { Input } from '../components/FormElements';
import { Container, PageTitle } from '../components/Layout';
import WhiteLabel from '../filters/WhiteLabel';
import useModal from '../hooks/useModal';
import { AddRecipientForm } from '../invites';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';
import { createPendingInvites } from './api/createPendingInvites';
import { AddRecipientList } from './components/AddRecipientList';
import { useAddPeople } from './hooks/useAddPeople';

export const Label = styled.label`
  display: block;
  margin: 0 0 ${Grid._3};
  color: ${Type.Color.dark};
  font-weight: ${Type.Weight.semibold};
`;

const Header = styled.header`
  margin-top: ${Grid._8};
  margin-bottom: ${Grid._7};
  display: flex;
  justify-content: space-between;
`;

const PersonalMessageContainer = styled.div`
  margin-top: ${Grid._8};
  margin-bottom: ${Grid._5};
`;

const EasyLinkContainer = styled.div`
  gap: ${Grid._4};
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  button {
    width: fit-content;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Grid._6};
  margin-bottom: ${Grid._12};

  legend {
    margin-bottom: ${Grid._4};
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const crumbs = [
  { name: 'Organization', route: '#/org' },
  { name: 'People', route: '#/people' },
  { name: 'Add People' },
];

export function AddPeople() {
  const { recipients, setRecipients, addRecipient, removeRecipient, easyLink } = useAddPeople();
  const [personalMessage, setPersonalMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const user = useUser();
  const userId = user?.userId || '';
  const orgId = user?.lastSelectedAccount || '';

  const submit = () => {
    setIsSubmitting(true);
    createPendingInvites(recipients, orgId, personalMessage, userId, userId)
      .then(({ successRecipients, failedRecipients }) => {
        setRecipients([]);
        setIsSubmitting(false);

        if (failedRecipients.length === 0) {
          alertService.showOnNextPage(`Invitation${successRecipients.length > 1 ? 's' : ''} sent`);
          windowService.redirectTo('/people');
        } else {
          setRecipients(failedRecipients);
          const numFailed = failedRecipients.length;
          alertService.showError(
            `Failed to send ${numFailed} invitation${numFailed > 1 ? 's' : ''}. Please try again.`
          );
        }
      })
      .catch((error: Error) => {
        handleError(error);
        setIsSubmitting(false);
      });
  };

  const [modal, openModal, dismissHandler] = useModal((type: string, payload: undefined, dismissModal: () => void) => {
    if (type === 'easyLink')
      return (
        <EasyLinkModal
          link={easyLink.link || ''}
          isLoading={easyLink.isLoading}
          description={`
          <div>
            <h4>
            Anyone who uses this link will automatically be added to your ${WhiteLabel.labels.org}.
            </h4>
            <h4>
              Please share this link <b>directly</b> with ${WhiteLabel.labels.org} members. You can send it many ways including:
            </h4>
            <ul style="margin-left: 16px;">
              <li>Text Messages</li>
              <li>Facebook and Facebook Messenger</li>
              <li>${WhiteLabel.labels.org} Management Systems</li>
              <li>Email</li>
              <li>A Special Page on Your Website</li>
            </ul>
          </div>
        `}
          handleDismiss={dismissModal}
        />
      );
    return null;
  });

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container>
        <Header>
          <PageTitle>
            <h1>Add People</h1>
            <p>
              {`Send an invitation to join your ${WhiteLabel.labels.org} or use the Share Easy Link button to copy your
              ${WhiteLabel.labels.org}'s easy link`}
            </p>
          </PageTitle>
          <EasyLinkContainer>
            <OutlineButton onClick={() => openModal('easyLink')}>
              <span className="fas fa-share-square"></span> Share Easy Link
            </OutlineButton>
          </EasyLinkContainer>
        </Header>
        <ContentContainer>
          <div>
            <AddRecipientForm
              recipients={recipients.map(item => ({
                ...item,
                firstName: item.firstName || '',
                lastName: item.lastName || '',
              }))}
              addRecipient={addRecipient}
            />
            <PersonalMessageContainer>
              <Label>Personal Message</Label>

              <Input
                as="textarea"
                rows={5}
                placeholder="Enter a personal message"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setPersonalMessage(e.target.value)}
              />
            </PersonalMessageContainer>
            <FormFooter>
              <PrimaryButton
                onClick={submit}
                disabled={recipients.length === 0 || isSubmitting}
                operating={isSubmitting}
              >
                Send Invitations
              </PrimaryButton>
            </FormFooter>
          </div>
          <AddRecipientList recipients={recipients} addRecipient={addRecipient} removeRecipient={removeRecipient} />
        </ContentContainer>
        {modal}
      </Container>
    </>
  );
}
