import { useEffect, useState } from 'react';
import { useUser } from '../../authentication';
import orgService from '../../services/orgService';
import { PendingInviteRecipient } from '../models/PendingInviteRecipient';

export const useAddPeople = () => {
  const [recipients, setRecipients] = useState<PendingInviteRecipient[]>([]);
  const [easyLink, setEasyLink] = useState<{ link?: string; isLoading: boolean }>({ isLoading: true });
  const user = useUser();
  const orgId = user?.lastSelectedAccount || '';

  useEffect(() => {
    orgService.getEasyLink(orgId).then(response => {
      if (!response.prefix) {
        setEasyLink({ link: undefined, isLoading: false });
      } else {
        setEasyLink({ link: window.location.origin + '/#/easy-link/' + response.prefix, isLoading: false });
      }
    });
  }, []);

  const addRecipient = (recipient: PendingInviteRecipient) => {
    setRecipients(prev => [...prev, recipient]);
  };

  const removeRecipient = (recipient: PendingInviteRecipient) => {
    setRecipients(prev => prev.filter(r => r.email !== recipient.email));
  };

  return { recipients, setRecipients, addRecipient, removeRecipient, easyLink };
};
