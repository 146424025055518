import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Border, Grid, Color, Type } from '../../StyleGuide';
import Overlay from '../../components/Overlay';
import BibleStudyHeaderImg from './BibleStudyHeaderImg';
import NewPurchasesOverlayInstructions from '../NewPurchasesOverlayInstructions';
import SectionHeader from '../../components/SectionHeader';
import WhiteLabel from '../../filters/WhiteLabel';
import cacheService from '../../services/cacheService';
import { CurriculumBrandWrapper } from '../../contexts/CurriculumContext';
import { Curriculum } from '../models/curriculum';

type NewPurchasesOverlayProps = {
  showOverlay?: boolean;
  curriculum: Array<{ curriculum: Curriculum; brand: any; name: string }>;
  instructionsType: string;
  dismissHandler?: () => void;
};

const Container = styled.div`
  max-width: 835px;
  border-radius: ${Border.radius};
`;

const UpperContainer = styled.div`
  padding: ${Grid._8} ${Grid._6} 0 ${Grid._6};
`;

const Header = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${Grid._1};
  right: 0;
  background: none;
  border: 0;
  padding: 0;
  text-decoration-line: underline;
  color: ${Color.Blue._50};
`;

const SubscriptionContainer = styled.div`
  margin-top: ${Grid._5};
`;

const LogoImage = styled.img`
  width: min(100%, 230px);
  height: 80px;
  border-radius: ${Border.radius};
  padding: ${Grid._4};
  background: ${Color.Gray._10};
`;

const SubscriptionBrand = styled.div`
  color: ${Type.Color.medium};
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.normal};
  margin: ${Grid._3} 0 ${Grid._2} 0;
`;
const SubscriptionName = styled.div`
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.bold};
`;

export default function NewPurchasesOverlay({
  showOverlay = false,
  curriculum = [],
  instructionsType,
  dismissHandler,
}: NewPurchasesOverlayProps) {
  const [isVisible, setIsVisible] = useState(showOverlay);
  const [recentPurchases, setRecentPurchases] = useState<Curriculum[]>([]);
  const recentlyPairedItemNumbers: string[] = cacheService.get('pairedItemNumbers') || [];
  const gridLicenseItemNumbers = ['005801686'];

  const dismiss = () => (dismissHandler !== undefined ? dismissHandler() : setIsVisible(false));

  const getSubscriptionInformation = (crm: any) => {
    const recentPurchases: Curriculum[] = [];

    crm.map((bs: any) => {
      recentlyPairedItemNumbers.forEach(itemNumber => {
        const curriculum = bs.curriculum.find(
          (crm: any) => crm.license.findIndex((l: any) => l.itemNumber == itemNumber) > -1
        );
        if (curriculum) {
          recentPurchases.push({ curriculum: true, brand: bs.brand, name: curriculum.name } as Curriculum);
        }
      });
    });

    recentlyPairedItemNumbers.forEach(itemNumber => {
      if (gridLicenseItemNumbers.includes(itemNumber)) {
        recentPurchases.push({ brand: { name: 'Training' }, name: 'Ministry Grid' } as Curriculum);
      }
    });

    return recentPurchases;
  };

  useEffect(() => {
    if (recentlyPairedItemNumbers.length) {
      setRecentPurchases(getSubscriptionInformation(curriculum));
      setIsVisible(true);
    }
    return () => {
      cacheService.remove('pairedItemNumbers');
    };
  }, []);

  return isVisible ? (
    <>
      <Overlay dismissHandler={dismiss}>
        <Container data-qa-hook="newCurriculumModal">
          <UpperContainer>
            {recentPurchases.length ? (
              <>
                <Header>
                  <SectionHeader title="New Purchases" />
                  <CloseButton data-qa-hook="newCurriculumModalClose" onClick={() => dismiss()}>
                    Close
                  </CloseButton>
                </Header>

                <h4>{`Your ${WhiteLabel.labels.org} is now connected to the following Ministry Grid subscriptions:`}</h4>

                <SubscriptionContainer className="grid-container grid-sm-col-4 grid-gap-40">
                  {recentPurchases.map((license, i) => (
                    <div className="grid-sm-col-span-1" key={i}>
                      {license.curriculum ? (
                        <CurriculumBrandWrapper brandCode={license.brand.code}>
                          {({ brand }: { brand: Curriculum['brand'] }) => (
                            <BibleStudyHeaderImg src={brand.imgSrc} alt={brand.name} background={brand.background} />
                          )}
                        </CurriculumBrandWrapper>
                      ) : (
                        <LogoImage alt={WhiteLabel.name} src={WhiteLabel.logoSmSrc || WhiteLabel.logoSrc} />
                      )}
                      <SubscriptionBrand>{license.brand.name}</SubscriptionBrand>
                      <SubscriptionName>{license.name}</SubscriptionName>
                    </div>
                  ))}
                </SubscriptionContainer>
              </>
            ) : (
              <>
                <Header>
                  <SectionHeader title="Digital Curriculum" />
                  <CloseButton onClick={() => dismiss()}>Close</CloseButton>
                </Header>

                <h4>How do digital curriculum subscriptions work?</h4>
              </>
            )}
          </UpperContainer>
          <NewPurchasesOverlayInstructions type={instructionsType} />
        </Container>
      </Overlay>
    </>
  ) : null;
}
