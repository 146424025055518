import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../../StyleGuide';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import ErrorMessage from '../../components/ErrorMessage';
import { PendingInviteRecipient } from '../models/PendingInviteRecipient';
import { Input } from '../../components/FormElements';

export const Label = styled.label`
  display: block;
  margin: 0 0 ${Grid._3};
  color: ${Type.Color.dark};
  font-weight: ${Type.Weight.semibold};
`;

const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

type BulkImportModalProps = {
  addRecipient: (recipient: PendingInviteRecipient) => void;
  handleDismiss: () => void;
};

export default function BulkImportModal({ addRecipient, handleDismiss }: BulkImportModalProps) {
  const [rawEmailList, setRawEmailList] = useState<string>('');
  const [recipientEmails, setRecipientEmails] = useState<string[]>([]);
  const [invalidRecipientEmails, setinvalidRecipientEmails] = useState<string[]>([]);

  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const newInvalidEmails: string[] = [];

    recipientEmails.forEach(email => {
      if (!emailRegex.test(email)) {
        newInvalidEmails.push(email);
      }
    });

    setinvalidRecipientEmails(newInvalidEmails);
    if (newInvalidEmails.length === 0) {
      recipientEmails.forEach(email => {
        const recipient: PendingInviteRecipient = {
          email,
        };
        addRecipient(recipient);
      });
      handleDismiss();
    }
  };

  const handleEmailChange = (value: string) => {
    setRawEmailList(value);

    const emails = value
      .split(',')
      .map(email => email.trim())
      .filter(email => email);
    setRecipientEmails(emails);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss} disableBackgroundDismiss>
      <ModalHeader>
        <h3>Bulk Import</h3>
      </ModalHeader>
      <ModalBody preventScroll>
        <Label htmlFor="email-address">{`Email Address (separated by commas):`}</Label>
        <TextAreaContainer>
          <Input
            as="textarea"
            id="email-address"
            value={rawEmailList}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleEmailChange(e.target.value)}
            rows={4}
            placeholder={`Enter comma separated email addresses.\nFor example: blake@gmail.com, courtney@yahoo.com, mitchell@icloud.com`}
          />
        </TextAreaContainer>
        {invalidRecipientEmails.length > 0 && (
          <ErrorMessage>Invalid emails: {invalidRecipientEmails.join(', ')}</ErrorMessage>
        )}
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton onClick={handleSubmit} disabled={recipientEmails.length === 0}>
          Add Emails
        </MediumPrimaryButton>
        <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
}
