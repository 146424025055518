import React from 'react';
import styled from 'styled-components';
import { LinkStyleButton, SmallRoundedIconButton } from '../../components/Buttons';
import { RaisedContainer } from '../../components/Layout';
import { Color, Grid } from '../../StyleGuide';
import { limitText } from '../../utils/textUtils';
import useModal from '../../hooks/useModal';
import BulkImportModal from './BulkImportModal';
import { PendingInviteRecipient } from '../models/PendingInviteRecipient';

const RecipientListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${Grid._4};
`;

const RecipientListContainer = styled(RaisedContainer)`
  width: 100%;
  padding: ${Grid._4};
`;

const RecipientListItemContainer = styled.div`
  display: grid;
  margin-top: ${Grid._4};
`;

const RecipientListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  width: 100%;
  padding: ${Grid._4} 0;

  & + & {
    border-top: 1px solid ${Color.Gray._20};
  }

  h6,
  p {
    margin: 0;
  }
`;

type RecipientListProps = {
  recipients: PendingInviteRecipient[];
  addRecipient: (recipient: PendingInviteRecipient) => void;
  removeRecipient: (recipient: PendingInviteRecipient) => void;
};

export const AddRecipientList = ({ recipients, addRecipient, removeRecipient }: RecipientListProps) => {
  const [modal, openModal, dismissHandler] = useModal((type: string, payload: undefined, dismissModal: () => void) => {
    if (type === 'bulkImport') return <BulkImportModal addRecipient={addRecipient} handleDismiss={dismissModal} />;
    return null;
  });

  return (
    <RecipientListContainer>
      <RecipientListHeader>
        <h6>{recipients.length} Recipients</h6>

        <LinkStyleButton onClick={() => openModal('bulkImport')}>Bulk Import</LinkStyleButton>
      </RecipientListHeader>
      <RecipientListItemContainer>
        {recipients.map(recipient => (
          <RecipientListItem key={recipient.email}>
            <div>
              <h6>
                {recipient.firstName} {recipient.lastName}
              </h6>
              <p>{limitText(recipient.email)}</p>
            </div>
            <SmallRoundedIconButton onClick={() => removeRecipient(recipient)} aria-label={'remove-' + recipient.email}>
              <i className="far fa-trash-alt fa-fw"></i>
            </SmallRoundedIconButton>
          </RecipientListItem>
        ))}
      </RecipientListItemContainer>
      {modal}
    </RecipientListContainer>
  );
};
