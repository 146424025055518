import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import curriculumService from '../../services/curriculumService';
import { FormField, Label, FormFieldDate, Input } from '../../components/FormElements';
import DatePicker from 'react-datepicker';
import { Grid, Type, Breakpoints } from '../../StyleGuide';
import Breadcrumbs from '../../components/Breadcrumbs';
import useCurriculum from '../../hooks/useCurriculum';
import { PrimaryButton, OutlineButton } from '../../components/Buttons';
import BibleStudyBanner from '../components/BibleStudyBanner';
import LoadingState from '../../components/LoadingState';
import { Container, PageTitle } from '../../components/Layout';
import windowService from '../../services/windowService';
import alertService from '../../services/AlertService';
import { handleError } from '../../utils/apiUtils';
import { ThreeTwoAspectRatioPlaceholder } from '../../components/Layout';
import ImageCrop, { ImageCropUtils } from '../../components/ImageCrop';
import ErrorMessage from '../../components/ErrorMessage';
import ManageCurriculumMaterialListItem from './ManageCurriculumMaterialListItem';
import EmptyState from '../../components/EmptyState';
import { OrderingDragContainer } from '../../components/OrderingElements';

const PageContainer = styled(Container)`
  margin-bottom: ${Grid._10};
`;

const SessionForm = styled(Form)`
  margin: 0 auto;
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    padding: 0;
  }
  @media print {
    padding: 0;
  }
`;

const MaterialContainer = styled.div`
  margin: 0 auto;
  margin-bottom: ${Grid._8};
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    padding: 0;
  }
  @media print {
    padding: 0;
  }
  & > ul > div > li:last-child > div {
    border-bottom: none;
  }
`;

const MaterialHeaderContainer = styled.div`
  display: flex;
  margin-top: ${Grid._11};
  margin-bottom: ${Grid._5};
  justify-content: space-between;
  align-items: center;
  & > h1 {
    font-size: ${Type.Scale._4};
    margin: 0;
  }
`;

const SessionSettingPageTitle = styled(PageTitle)`
  display: flex;
  align-items: center;
  margin: ${Grid._6} 0 ${Grid._10} 0;
  @media (max-width: ${Breakpoints.screen_xs}) {
    margin: ${Grid._4} 0 ${Grid._5} 0;
    & h1 {
      font-size: ${Type.Scale._5};
    }
  }
`;

const SessionSettingContentContainer = styled.div`
  display: flex;
  gap: ${Grid._6};
  flex-direction: row;
  flex-wrap: wrap;
`;

const SessionImageContainer = styled.div`
  flex: 1 1 100%;
  max-width: 420px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${Grid._4};
  margin: ${Grid._6} 0 ${Grid._5} 0;
`;

const SaveButton = styled(PrimaryButton)`
  @media (max-width: ${Breakpoints.screen_xs}) {
    width: 100%;
  }
`;

const FieldsContainer = styled.div`
  flex: 1;
  min-width: 320px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${Grid._6};

  > ${FormFieldDate} {
    flex: 0 0 220px;
  }
`;

const ReorderableMaterialListItemContainer = styled(OrderingDragContainer)`
  display: flex;
  flex-direction: column;
`;

const ReorderableManageCurriculumMaterialListItem = SortableElement(props => (
  <ManageCurriculumMaterialListItem {...props} />
));

const ManageCurriculumSession = () => {
  const { curriculumId, unitId, sessionId } = useParams();
  const { brand, ageCategory } = useCurriculum();
  const [initialImage, setInitialImage] = useState(null);
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    session: {},
    unit: { name: '' },
    materials: [],
  });
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  const { isLoading, isError, session, materials } = data;

  useEffect(() => {
    curriculumService
      .getIndividualUnit(unitId, curriculumId)
      .then(unit => {
        setCrumbs(prevCrumbs => [
          ...prevCrumbs,
          {
            name: `${unit.name}`,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}`,
          },
          { name: sessionId ? `Edit Session` : 'New Session' },
        ]);

        if (sessionId) {
          return Promise.all([
            curriculumService.getSession(unitId, sessionId),
            curriculumService.getSessionMaterials(sessionId),
            curriculumService.getCurriculumCollections(curriculumId),
          ]);
        } else {
          return Promise.resolve([null, []]);
        }
      })
      .then(([sessionData, materials, collections]) => {
        const session = sessionData || {};
        const sessionMaterials = materials || [];
        const materialsWithCollections = sessionMaterials.map(material => ({
          ...material,
          collections: material.seriesIds.map(sid => collections.find(c => c.seriesId === sid)),
        }));
        setData(prevData => ({
          ...prevData,
          isError: false,
          session,
          materials: materialsWithCollections,
          isLoading: false,
        }));
        if (session.image) {
          setInitialImage(session.image);
        }
      })
      .catch(error => {
        handleError(error);
        setData(prevData => ({ ...prevData, isError: true, isLoading: false }));
      });
  }, []);

  const getInitialFormValues = sessionItem => {
    return sessionItem
      ? {
          ...sessionItem,
          useDate: sessionItem.useDate ? new Date(sessionItem.useDate) : null,
          publishStartDate: sessionItem.publishStartDate ? new Date(sessionItem.publishStartDate) : null,
          publishEndDate: sessionItem.publishEndDate ? new Date(sessionItem.publishEndDate) : null,
        }
      : {
          name: '',
          description: '',
          useDate: '',
          publishStartDate: null,
          publishEndDate: null,
        };
  };

  const uploadSessionImage = image =>
    new Promise((resolve, reject) => {
      const curriculumFileName = ImageCropUtils.generateFilename();
      if (ImageCropUtils.isCroppedImage(image)) {
        ImageCropUtils.convertCroppedImageToBlob(image)
          .then(blob => curriculumService.uploadCurriculumImage(curriculumFileName, blob))
          .then(() => resolve(curriculumFileName))
          .catch(reject);
      } else {
        resolve(image);
      }
    });

  const uploadAndSetImage = (unitId, sessionId, image) => {
    return uploadSessionImage(image).then(uploadedImage => {
      curriculumService.updateSessionImage(unitId, sessionId, uploadedImage);
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const { name, useDate, description, image } = values;
    if (sessionId) {
      curriculumService
        .updateSessionName(unitId, sessionId, name)
        .then(() => {
          setData(prevData => ({
            ...prevData,
            session: {
              ...prevData.session,
              name,
            },
          }));
          alertService.show('Session Updated');

          if (description) {
            curriculumService.updateSessionDescription(unitId, sessionId, description);
          }
          if (image !== initialImage) {
            uploadAndSetImage(unitId, sessionId, image);
          }
        })
        .catch(error => {
          handleError(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      const { publishStartDate, publishEndDate } = curriculumService.getSessionPublishDates(useDate);

      curriculumService
        .createSession(curriculumId, unitId, name, useDate, publishStartDate, publishEndDate)
        .then(newSession => {
          return Promise.all([
            curriculumService.updateSessionDescription(unitId, newSession.id, description),
            uploadAndSetImage(unitId, newSession.id, image),
          ]).then(() => {
            alertService.showOnNextPage('Session Added');

            windowService.redirectTo(
              `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${newSession.id}/settings`
            );
          });
        })
        .catch(error => {
          setSubmitting(false);
          handleError(error);
        });
    }
  };

  const updateItemIndex = (materials, oldIndex, newIndex) => {
    const materialListCopy = [...materials];
    materialListCopy.splice(newIndex, 0, materialListCopy.splice(oldIndex, 1)[0]);
    const orderedMaterialList = materialListCopy.map((material, index) => ({
      ...material,
      order: index,
    }));
    setData(prevData => ({ ...prevData, materials: orderedMaterialList }));
    curriculumService
      .updateMaterialOrderForSession(sessionId, orderedMaterialList)
      .then(() => {
        alertService.show('Materials Updated');
      })
      .catch(error => {
        handleError(error);
      });
  };

  const handleRedirectToAddMaterial = () => {
    windowService.redirectTo(
      `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${sessionId}/material`
    );
  };

  const handleCancel = () => {
    windowService.redirectTo(`#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}`);
  };

  const handleDeleteMaterial = materialId => {
    return curriculumService
      .deleteSessionMaterial(sessionId, materialId)
      .then(response => {
        const deletedMaterialId = response.data.materialId;
        const updatedMaterials = materials.filter(material => material.materialId !== deletedMaterialId);
        setData(prevData => ({ ...prevData, materials: updatedMaterials }));
        alertService.show('Material Removed');
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
      <BibleStudyBanner />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <Container>
          <ErrorMessage />
        </Container>
      ) : (
        <PageContainer>
          <Formik
            initialValues={getInitialFormValues(sessionId && session)}
            validationSchema={Yup.object({
              name: Yup.string().max(64, 'Name is too long').required('A name is required'),
              useDate: Yup.date().when([], {
                is: () => !sessionId,
                then: Yup.date().required('A use date is required'),
                otherwise: Yup.date(),
              }),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, isSubmitting, errors, submitCount }) => {
              return (
                <SessionForm>
                  <SessionSettingPageTitle>
                    <h1>{session.name}</h1>
                  </SessionSettingPageTitle>
                  <SessionSettingContentContainer>
                    <SessionImageContainer>
                      <Label>Poster</Label>
                      <ThreeTwoAspectRatioPlaceholder actionable={!values.image}>
                        <ImageCrop
                          key={values.image}
                          defaultImage={values.image}
                          aspectRatio={3 / 2}
                          onUpdate={img => setFieldValue('image', img)}
                        />
                      </ThreeTwoAspectRatioPlaceholder>
                    </SessionImageContainer>
                    <FieldsContainer>
                      <FormField
                        label="Name"
                        data-qa-hook="sessionName"
                        name="name"
                        placeholder="Enter Name"
                        onChange={e => setFieldValue('name', e.target.value)}
                      />

                      <FormField
                        data-qa-hook="sessionDescription"
                        as="textarea"
                        rows="7"
                        label="Description"
                        name="description"
                        placeholder="Enter Description"
                      />
                      {submitCount > 0 && errors.description && <ErrorMessage>{errors.description}</ErrorMessage>}
                      {!sessionId && (
                        <DatePickerContainer>
                          <FormFieldDate disabled={values.isAlwaysAvailable}>
                            <Label>Use Date</Label>
                            <DatePicker
                              data-qa-hook="useDatePicker"
                              selected={values.useDate || null}
                              onChange={date => setFieldValue('useDate', date)}
                              customInput={<Input />}
                              dateFormat="MM/dd/yyyy"
                              placeholderText="Select Date"
                              minDate={values.publishStartDate || new Date()}
                              maxDate={values.publishEndDate}
                            />
                            {submitCount > 0 && errors.useDate && <ErrorMessage>{errors.useDate}</ErrorMessage>}
                          </FormFieldDate>
                        </DatePickerContainer>
                      )}
                    </FieldsContainer>
                  </SessionSettingContentContainer>
                  <SaveButtonContainer>
                    {!sessionId && (
                      <OutlineButton type="button" onClick={() => handleCancel()}>
                        Cancel
                      </OutlineButton>
                    )}
                    <SaveButton
                      type="submit"
                      disabled={isSubmitting}
                      operating={isSubmitting}
                      data-qa-hook="sessionSave"
                    >
                      Save Session
                    </SaveButton>
                  </SaveButtonContainer>
                </SessionForm>
              );
            }}
          </Formik>
          {sessionId && (
            <MaterialContainer>
              <MaterialHeaderContainer>
                <h1>Materials</h1>
                <OutlineButton onClick={() => handleRedirectToAddMaterial()} data-qa-hook="materialAdd">
                  <i className="icomoon-addList"></i>Add Material
                </OutlineButton>
              </MaterialHeaderContainer>
              {materials.length > 0 ? (
                <ReorderableMaterialListItemContainer
                  data-qa-hook="sessionList"
                  onSortEnd={({ oldIndex, newIndex }) => updateItemIndex(materials, oldIndex, newIndex)}
                  useDragHandle
                >
                  {materials.map((material, index) => (
                    <ReorderableManageCurriculumMaterialListItem
                      key={material.materialId}
                      index={index}
                      data-qa-hook="materialItem"
                      material={material}
                      handleDeleteMaterial={handleDeleteMaterial}
                    />
                  ))}
                </ReorderableMaterialListItemContainer>
              ) : (
                <EmptyState
                  title="There are no materials"
                  description="Please use the Add Material button to begin creating materials"
                />
              )}
            </MaterialContainer>
          )}
        </PageContainer>
      )}
    </>
  );
};

export default ManageCurriculumSession;
